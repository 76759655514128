import * as colors from "./colors";

export default {
  fontFamily: ["sans-serif"].join(","),
  h1: {
    fontWeight: "700",
    fontSize: "52px",
    letterSpacing: "-0.24px",
    lineHeight: "40px",
    fontFamily: "sans-serif",
  },
  h2: {
    fontWeight: "700",
    fontSize: "32px",
    letterSpacing: "-0.24px",
    lineHeight: "44px",
    fontFamily: "sans-serif",
  },
  h3: {
    fontFamily: "sans-serif",
    fontStyle: "700",
    fontWeight: "700",
    fontSize: "40px",
    lineHeight: "32px",
  },
  h4: {
    fontWeight: "700",
    fontSize: "34px",
    letterSpacing: "-0.06px",
    lineHeight: "30px",
    fontFamily: "sans-serif",
  },
  h5: {
    fontWeight: "700",
    fontSize: "34px",
    letterSpacing: "-0.05px",
    lineHeight: "30px",
  },
  h6: {
    fontWeight: "400",
    fontSize: "30px",
    letterSpacing: "-0.05px",
    lineHeight: "30px",
  },
  subtitle1: {
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "26px",
    lineHeight: "28px",
  },
  subtitle2: {
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "700",
    fontSize: "20px",
    lineHeight: "28px",
  },
  body1: {
    fontSize: "16px",
    letterSpacing: "-0.05px",
    fontStyle: "normal",
    fontWeight: "400",
    lineHeight: "20px",
    fontFamily: "sans-serif",
  },
  body2: {
    fontFamily: "sans-serif",
    fontStyle: "normal",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "24px",
  },
  button: {
    fontSize: "14px",
  },
  caption: {
    fontSize: "12px",
    letterSpacing: "0.3px",
    lineHeight: "16px",
  },
};
