// Palette

import { PRI_400, NTRL_100, PRI_100 } from "../colors";

export default {
    styleOverrides: {
        root: {
            textTransform: "none",
            fontSize: 12,
            padding: "5px, 10px, 5px, 10px",
            width: "100%",
        },
        outlined: {
            color: PRI_400,
            backgroundColor: NTRL_100,
            borderColor: PRI_400,
            "&:hover": {
                borderColor: PRI_100,
            },
        },
        contained: {
            backgroundColor: PRI_400,
            color: NTRL_100,
            "&:hover": {
                borderColor: PRI_400,
                backgroundColor: PRI_100,
                color: PRI_400,
            },
        },
        containedPrimary: {
            backgroundColor: PRI_400,
        },
    },
};
