import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// const HomePage = React.lazy(() => import("./pages/HomePage"));
// const CinemaPage = React.lazy(() => import("./pages/CinemaPage"));
const CreativePage = React.lazy(() => import("./pages/CreativePage"));
// const PhotographyPage = React.lazy(() => import("./pages/PhotographyPage"));
// const WebDevPage = React.lazy(() => import("./pages/WebDevPage"));
const InvideHowPage = React.lazy(() => import("./pages/InvideHowPage"));
const InvidePage = React.lazy(() => import("./pages/InvidePage"));
const index = (props) => {
  return (
    <>
      <Routes>
        <Route exact path="/" element={<CreativePage />} />
        <Route exact path="*" element={<>Not Found</>} />
        <Route exact path="/how/invide" element={<InvideHowPage />} />
        {/* <Route exact path="/invide" element={<InvidePage />} /> */}
        {/* <Route exact path="/invide" element={<InvidePage />} /> */}
        {/* <Route exact path="/cinematography" element={<CinemaPage />} />
        <Route exact path="/photography" element={<PhotographyPage />} /> */}
        {/* <Route exact path="/creative-branding" element={<CreativePage />} /> */}
        {/* <Route exact path="/web-development" element={<WebDevPage />} /> */}
        {/* <Route exact path="/exception-handling" element={<ExceptionPage />} /> */}
      </Routes>
    </>
  );
};

export default index;
